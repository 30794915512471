import React, { useState, useEffect } from "react";
import { graphql, StaticQuery } from "gatsby"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"


const ContactPage = ({ location, data }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  console.log('executeRecaptcha', executeRecaptcha)

  const siteTitle = data.site.siteMetadata.title

  const [contactReason, setContactReason] = useState("");
  const [showExtraFields, setShowExtraFields] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reason = queryParams.get("reason") || 'general-question';

    setContactReason(reason || '');
    setShowExtraFields(reason === "purchase-inquiry");
  }, [location.search]); // Ensure this depends on location.search

  const handleReasonChange = (e) => {
    const value = e.target.value;
    console.log("handleReasonChange", value)
    setContactReason(value);
    setShowExtraFields(value === "purchase-inquiry");
  };

  const [mode, setMode] = useState("READY"); // READY, BUSY, COMPLETE, ERROR

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [emptyFields, setEmptyFields] = useState({});

  const validateEmail = (email) => {
    // Simple regex for basic email validation
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      let newEmptyFields = {};
      let formIsValid = true;

      if (!validateEmail(e.target.email.value)) {
        newEmptyFields.email = true;
        formIsValid = false;
      }

      if (!e.target.name.value) {
        newEmptyFields.name = true;
        formIsValid = false;
      }

      if (showExtraFields && !e.target.budget.value) {
        newEmptyFields.budget = true;
        formIsValid = false;
      }

      setEmptyFields(newEmptyFields);
      setSubmitAttempted(true);

      if (!formIsValid) {
        return
      }
      
      setMode("BUSY")

      try {
          console.log("handleSubmit", e)

          if (!executeRecaptcha) {
              console.log("!executeRecaptcha")
              throw "Not Ready"
          }

          const token = await executeRecaptcha('signup')
          console.log('token', token)

          let data = {
            reason: contactReason,
            email: e.target.email.value,
            name: e.target.name.value,
            token: token
          };
      
          // Only add properties if they exist and are not empty
          if (e.target.message && e.target.message.value) data.message = e.target.message.value;
          if (e.target.budget && e.target.budget.value) data.budget = e.target.budget.value;
          if (e.target.country && e.target.country.value) data.country = e.target.country.value;
          if (e.target.sculpture && e.target.sculpture.value) data.sculpture = e.target.sculpture.value;
      
          // Now, data is ready to be JSON-stringified and sent
          const jsonData = JSON.stringify(data);

          console.log('jsonData', jsonData)

          const submitResult = await fetch('https://p65jdfgz1l.execute-api.us-east-1.amazonaws.com/default/contact-form', {
              method: 'POST',
              headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-type': 'application/json'
              },
              body: jsonData 
          })

          const finalResult = await submitResult.json()

          console.log('Server Response', finalResult);

          if (finalResult.result === "OK") {
              setMode("COMPLETE")
              return
          } 

          throw "Unexpected Result"
      
      } catch(e) {
          console.log("ERROR", e)
          setMode("ERROR") // Error Mode
      }
  };


  return (
    <Layout title={siteTitle}>
      <SEO
        title="Contact"
        keywords={[
          `Parametric`,
          `Wall Sculpture`,
          `Contemporary Art`,
          `Dimensional Wall Art`,
          `3D Wall Art`,
          `Modern Art`,
          `Flower Art`,
          `Mosaic Art`,
          `Wood Wall Art`,
          `Sacred Geometry`,
          `Abstract Art`,
          `Wall Object`,
          `3D Printing`,
          `Mandala Art`,
          `Geometric Art`,
          `Trippy Art`,
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">

          <h2>{contactReason === 'purchase-inquiry' ? 'Purchase Inquiry' : 'Contact'}</h2>
          
          {
            {
            "READY": <div>

              <div>
                {contactReason === 'purchase-inquiry' 
                ? <p>Interested in acquiring a unique piece of art? Please fill out this form with your details and preferences. I'll get back to you soon to discuss further. Looking forward to connecting with you!</p> 
                : <p>Have a question or just want to say hello? I'd love to hear from you. Email me directly at <a href="mailto:hi@herschelshapiro.com">hi@herschelshapiro.com</a> or fill out this form, and I'll get back to you as soon as possible.</p>}
              </div>

              <form onSubmit={handleSubmit} noValidate>
                <label>
                  Reason for Contact:<span style={{ color: 'red' }}>*</span>
                  <select value={contactReason} onChange={handleReasonChange}>
                    <option value="general-question">General Question</option>
                    <option value="purchase-inquiry">Purchase Inquiry</option>
                  </select>
                </label>

                <label htmlFor="email" style={{marginTop:'15px'}}>Email:    
                  <span style={{ color: 'red' }}>{submitAttempted && emptyFields.email ? ' *Required' : ' *'}</span>
                  <input type="email" id="email" name="email" required placeholder="Your Email"/>
                </label>

                <label htmlFor="name" style={{marginTop:'15px'}}>Name:<span style={{ color: 'red' }}>{submitAttempted && emptyFields.name ? ' *Required' : ' *'}</span>
                  <input type="text" id="name" name="name" required placeholder="Your Name"/>
                </label>
                {showExtraFields && (
                  <>
                    <label style={{marginTop:'15px'}}>Anticipated Budget Range:<span style={{ color: 'red' }}>{submitAttempted && emptyFields.budget ? ' *Required' : ' *'}</span>
                        <input type="radio" id="budget1" name="budget" value="1500-2500" required/>
                        <label htmlFor="budget1">1,500 - 2,500 USD</label>
                        <input type="radio" id="budget2" name="budget" value="2500-5000"/>
                        <label htmlFor="budget2">2,500 - 5,000 USD</label>
                        <input type="radio" id="budget3" name="budget" value="more-5000"/>
                        <label htmlFor="budget3">More than 5,000 USD</label>
                    </label>
                          
                    <label style={{marginTop:'15px'}}>
                      Country for Shipping:
                      <input type="text" name="country" />
                    </label>

                    <label htmlFor="sculpture" style={{marginTop:'15px'}}>Sculpture of Interest:
                      <input type="text" id="sculpture" name="sculpture" placeholder="Sculpture Name"></input>
                    </label>
                  </>
                )}
                
                <label htmlFor="message" style={{marginTop:'15px'}}>Message:
                  <textarea id="message" name="message" rows="4" placeholder="Your message here..."></textarea>
                </label>
                
                <button type="submit" style={{marginTop:'15px'}}>Submit</button>
              </form>
              </div>,
            "BUSY": <div style={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center',
              marginTop: '40px' // Adjusts space above the spinner.
            }}>
              <div className="spinner" style={{ marginBottom: '10px' }}></div> {/* Adds space below the spinner */}
              <p style={{ margin: 0 }}>Sending your message...</p> {/* Uses paragraph tag for regular font size */}
            </div>,
            "COMPLETE": <div>
              <p className="centered-content">Your message was submitted successfully, and I will get back to you as soon as possible. Thank you for reaching out!</p>
            </div>,
            "ERROR": <div>
              <h2>Oops, there was an error.</h2>
            </div>
            }[mode]
          }


        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "herschel.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1360)
      }
    }
  }
`


export default props => {
  return (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <ContactPage location={props.location} data={data} {...props} />
      )}
    />
  )
}
